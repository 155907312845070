<template>
  <div class="page1">
    <Row class="listTop">
      <Col span="7">
        <div class="leftTop">
          <span class='title'><span class="title-text">机构简介</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe">{{dataList.organiztionExplain}}</div>
        </div>
        <div class="leftCenter">
          <span class='title'><span class="title-text">湖湘枢纽型公益平台简介</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe">
            <span>{{dataList.platformExplain}}</span>
          </div>
        </div>
        <div class="leftBottom">
          <span class="title"><span class="title-text">政社联动</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe2">
            <span v-for="(item,index) in dataList.governmentCommunity" :key="index">{{item.context}}</span>
          </div>
        </div>
      </Col>
      <Col span="10" class="chinaMap">
        <!--                <radar-part ref="chart6"></radar-part>-->
        <china-map ref="chinaMap"></china-map>
      </Col>
      <Col span="7">
        <div class="right right-1">
          <span class="title"><span class="title-text">公益服务数据</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="describe2">
            <div class="item">公益项目：<span class="countData">{{ classifyCount | getThousand }}个</span></div>
            <div class="item">服务人次：<span class="countData">{{ projectPeopleCount | getThousand }}人次</span></div>
            <div class="item">志愿者人数：<span class="countData">{{ projectPostulantPeopleCount | getThousand }}人</span></div>
            <div class="item">款物投入：<span class="countData">{{ projectPutFund | getThousand }}万元</span></div>
            <div class="item">社会组织参与数：<span class="countData">{{ projectSocietyCount | getThousand }}个</span></div>
          </div>
        </div>
        <div class="right right-2">
          <span class="title"><span class="title-text">各界关怀</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <pictures :List="dataList.caringImg"></pictures>
        </div>
        <div class="right right-3">
          <span class="title"><span class="title-text">社会认可</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <cooperation-logo :noticeList="noticeList"></cooperation-logo>
        </div>
        <div class="right right-4">
          <span class='title'><span class="title-text">共建单位</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="unit_choose">
            <div v-for="(item,index) in dataList.commentMonadImg" :key="index" :class="index==num?'on_unit':'unit'" @click="getUnitFn(item,index)">{{item.name}}</div>
          </div>
        </div>
      </Col>
    </Row>
    <cooperFather ref="cooper"></cooperFather>
  </div>
</template>

<script>
import {formatNum} from '@/lib/toThousands'
import {getIndexData} from "@/api/dataList";
const  cooperationLogo =()=> import("@/views/components/workPicture/cooperationLogo");
const chinaMap  = () => import('./components/indexMap');
const pictures =()=> import('./components/workPicture/picture');
const cooperFather =()=> import('./components/page1/cooperation_father')
import {isExternal} from '@/utils/validate'
import bus from "./eventbus.js"
// const webBar = () => import('./components/page1/webBar.vue');
// const pie = () => import('./components/pie')
// const ring = () => import('./components/page1/ring');
// const popularBar = () => import('./components/page1/popularBar');
// const lines = () => import('./components/page1/lines');
// const pieRing = () => import('./components/page1/pieRing');
// const colorsBar = () => import('./components/page6/colorsBar');
// const lineBar = () => import('./components/page1/lineBar');
export default {
  name: 'page1',
  props: {
    selectRangeDate: Array
  },
  components: {
    cooperationLogo,
    cooperFather,
    // radarPart,// 中心
    chinaMap, //湖南省地图
    pictures,
    // doubleArea, // 面积图
    // webBar, // 柱图
    // pie, // 饼图
    // worldMap, // 世界地图
    // ring, // 圆环
    // popularBar, // 柱图
    // lines, //折线图
    // pieRing, // 饼环图
    // colorsBar,// 柱图
    // funnel, // 漏斗图
    // lineBar, //柱图
    // areaChart // 面积图
  },
  created() {
    this.getIndexList()
  },
  data() {
    return {
      num:-1,
      chooseIndex:-1,
      dataList:[],
      // 社会认可
      noticeList:[],
      // 共建单位
      unitList:[],
      resizeFn: null,
      classifyCount:'',
      projectPeopleCount:'',
      projectPostulantPeopleCount:'',
      projectPutFund:'',
      projectSocietyCount:''
    }
  },
  methods: {
    async getIndexList(){
      const res = await getIndexData();
      this.dataList = res.rows[0];
      this.classifyCount = JSON.parse(this.dataList.allData.classifyCount);
      this.projectPeopleCount = JSON.parse(this.dataList.allData.projectPeopleCount);
      this.projectPostulantPeopleCount = JSON.parse(this.dataList.allData.projectPostulantPeopleCount);
      this.projectPutFund = JSON.parse(this.dataList.allData.projectPutFund);
      this.projectSocietyCount = JSON.parse(this.dataList.allData.projectSocietyCount);
      this.realSrcList(null)
      bus.$emit('share',this.dataList.organiztionName);
    },
    // 处理数组图片以逗号分隔传过来的数据
    realSrcList(name,item) {
      if(name==null){
        this.noticeList = [];
        let real_src_list = this.dataList.societyAcceptImg.split(",");
        real_src_list.forEach(item => {
          if (isExternal(item)) {
            return this.noticeList.push(item);
          }
          return this.noticeList.push('http://hunanqunyinghui.oss-cn-guangzhou.aliyuncs.com/' + item);
        });
        return this.noticeList;
      }else if(name=='unit'){
        this.unitList = [];
        let real_src_list = item.img.split(",");
        real_src_list.forEach(item => {
          if (isExternal(item)) {
            return this.unitList.push(item);
          }
          return this.unitList.push('http://hunanqunyinghui.oss-cn-guangzhou.aliyuncs.com/' + item);
        });
        return this.unitList;
      }
    },
    async getUnitFn(item,idx){
      console.log(item,idx)
      this.num = idx;
      this.$refs.cooper.title = item.name;
      this.$refs.cooper.dialogVisible = true;
      this.realSrcList('unit',item)
      this.$refs.cooper.List = this.unitList;
    },
  },
  filters:{
    getThousand(num){
      return formatNum(JSON.stringify(num));
    }
  },
  watch: {
    selectRangeDate: function () {
      for (let i = 1; i < 18; i++) {
        this.$refs['chart' + i].setChart();
      }
    }
  },
  mounted() {
    // this.resizeFn = this.$debounce(() => {
    //   // 通过捕获系统的onresize事件触发我们需要执行的事件
    //   for (let i = 1; i < 18; i++) {
    //     this.$refs['chart' + i].setChart();
    //   }
    // }, 500)
    // window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeFn)
  }

}
</script>

<style lang="less">
.page1 {
  //margin-top: 20px;
  height: 98%;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 20px 20px;
  background: #03044A;
  overflow: hidden;

  .listTop {
    height: 100%;

    .ivu-col {
      height: 100%;

      .leftTop {
        height: 42%;

      }
      .leftCenter{
        height: 22%;
        margin: 5% 0;
      }
      .right-1{
        height: 21%;
      }
      .right-4{
        height: 20%;
        .unit_choose{
          width: 80%;
          height: 20%;
          margin: 12% auto;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .unit{
            width: auto;
            min-width:28%;
            text-align: center;
            background-color: #2788b1;
            color: #fff;
            //height: 100%;
            //line-height: 25px;
            padding: 5px 10px;
            font-size: 16px;
            font-family: "华文细黑", Courier New, Courier, monospace;
            border-radius: 1px;
          }
          .on_unit{
            background-color: #fff;
            color: #2788b1;
            width: auto;
            min-width:28%;
            text-align: center;
            padding: 5px 10px;
            font-size: 16px;
            font-family: "华文细黑", Courier New, Courier, monospace;
            border-radius: 1px;
          }
        }
      }
      .right-2{
        height: 31%;
        margin: 3%;
      }
      .right-3{
        margin-bottom: 3%;
        height: 22%;
      }
      .right{
        margin-left: 20px;
        width: 96%!important;
      }
      .leftTop,.leftCenter,.leftBottom,.right{
        width:100%;
        border: 1px solid #0D2451;
        position: relative;
        box-sizing: border-box;
        background:rgb(8,25,79);
        .title {
          position: absolute;
          display: inline-block;
          color: #6EDDF1;
          border: 2px solid #6EDDF1;
          height: 18px;
          padding: 0 2px;
          left: 50%;
          transform: translate(-50%, -50%);
          .title-text {
            position: relative;
            font-size: 16px;
            background: #09102E;
            display: inline-block;
            padding: 0 4px;
            width: auto;
            transform: translateY(-5px);
            font-family:"宋体", "Times New Roman", Times, serif;;
          }
        }
        .describe2{
          flex-direction: column;
          line-height: 30px;
          padding-top:3%;
          overflow-y: scroll;
        }
        .describe{
          align-items: center;
          line-height: 35px;
          padding-top:2%;
          overflow-y: scroll;
        }
        .describe,.describe2{
          display: flex;
          margin:0 auto;
          font-size: 15px;
          color:rgb(167,198,235);
          width: 96%;
          height: 100%;
          font-family:"华文细黑",Courier New, Courier, monospace;
          //font-family:"华文仿宋",Georgia,"Times New Roman",Times,serif;
          .item{
            display: flex;
          }
          .countData{
            color:rgb(213,197,66);
          }
        }
      }
      .leftBottom{
        height: 29%;

        .ivu-row {
          &.topLine {
            height: 55%;
          }

          &.bottomPie {
            height: 45%;
          }

          .ivu-col {
            height: 100%;

            .charts-list {
              height: 100%;
              width: 100%;
            }
          }
        }

        .behavior {
          width: 100%;
          height: 100%;
        }

        .appUse {
          width: 100%;
          height: 100%;
        }
      }
      .right-top{
        margin-top: 0!important;
        margin-left: 20px;
        height: 50% !important;
      }
      .chinaMap{
        margin: 0 10px;
        height: 101%;
        background:rgb(8,25,79);
      }

      .rightTop-1 {
        width: 100%;
        height: 55%;
        border: 1px solid #0D2451;
        position: relative;
      }

      .rightTop-2 {
        width:96.5%;
        margin-top: 5%;
        height: 45.6%;
        margin-left: 20px;
        border: 1px solid #0D2451;
        position: relative;
        box-sizing: border-box;
        .rightTop-list {
          margin-top: 15px;
          width: 100%;
          height: 100%;

          .list {
            width: 30%;
            height: 100%;
            float: left;

            &:first-child {
              width: 40%;
            }
          }
        }
      }
      .right-bottom{
        margin-top: 4%;
        height: 30%;
        border: 1px solid #0D2451;
        position: relative;

        .ivu-row {
          &.topLine {
            height: 55%;
          }

          &.bottomPie {
            height: 45%;
          }

          .ivu-col {
            height: 100%;

            .charts-list {
              height: 100%;
              width: 100%;
            }
          }
        }

        .behavior {
          width: 100%;
          height: 100%;
        }

        .appUse {
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  .listBottom {
    height: 40%;

    .ivu-col-span-9 {
      width: 33.5%;
      margin-right: 0.6667%;
    }

    .ivu-col-span-4 {
      width: 17.66667%;
      margin-right: 0.6667%;
    }
    .loudou{
      height: 500px!important;
    }

    .ivu-col {
      height: 100%;
    }
  }
}
.describe2::-webkit-scrollbar { /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.describe2::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #0b316f;
}

.describe2::-webkit-scrollbar-track { /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(8, 25, 79);
}
.describe::-webkit-scrollbar { /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.describe::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #0b316f;
}

.describe::-webkit-scrollbar-track { /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(8, 25, 79);
}
</style>
